var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      attrs: { top: "", right: "", color: "success", timeout: _vm.timeout },
      model: {
        value: _vm.snackbar,
        callback: function ($$v) {
          _vm.snackbar = $$v
        },
        expression: "snackbar",
      },
    },
    [
      _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-check-circle")]),
      _vm._v(" " + _vm._s(_vm.text) + "\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }