var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: _vm.getId() } },
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "py-0" },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "mb-0 pb-0" }, [
                    _c(
                      "div",
                      {
                        staticClass: "float-right content-search-action-icons",
                      },
                      [
                        _vm.showIcon
                          ? _c(
                              "span",
                              [
                                _vm.$store.getters.getDebugShowResultScores
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { left: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: { color: "red" },
                                                        on: {
                                                          click:
                                                            _vm.openFullExplanationLink,
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                                    help_outline\n                                "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          683455801
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "tooltip-content" },
                                          [
                                            _vm._v(
                                              "\n                                Score: " +
                                                _vm._s(_vm.resultScore) +
                                                "\n                                "
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "\n                                Term Frequency: " +
                                                _vm._s(_vm.termFrequency) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "item-link",
                                                {
                                                  attrs: {
                                                    meta: _vm.meta,
                                                    documentId: _vm.documentId,
                                                    breadcrumbs: _vm.breadcrumbs
                                                      ? _vm.breadcrumbs
                                                      : {},
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "right mr-2",
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                                        input\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1670675873
                                    ),
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "tooltip-content" },
                                      [_vm._v("Jump to this Section")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on: onMenu }) {
                                            return [
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    "offset-y": "",
                                                    transition: "0",
                                                    location: "start",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on: onTooltip,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "right mr-2",
                                                                },
                                                                {
                                                                  ...onMenu,
                                                                  ...onTooltip,
                                                                }
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "file_copy"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list",
                                                    [
                                                      _c(
                                                        "v-list-item",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "second-event",
                                                              rawName:
                                                                "v-second-event",
                                                              value: {
                                                                method:
                                                                  "registerClick",
                                                                value: "2",
                                                              },
                                                              expression:
                                                                "{ method: 'registerClick', value: '2' }",
                                                            },
                                                          ],
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.sectionCopyAction(
                                                                false
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                          Copy this Section to Clipboard (text only)\n                                      "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-list-item",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "second-event",
                                                              rawName:
                                                                "v-second-event",
                                                              value: {
                                                                method:
                                                                  "registerClick",
                                                                value: "2",
                                                              },
                                                              expression:
                                                                "{ method: 'registerClick', value: '2' }",
                                                            },
                                                          ],
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.sectionCopyAction(
                                                                true
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                          Copy this Section to Clipboard (with formatting)\n                                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2814706781
                                    ),
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "tooltip-content" },
                                      [_vm._v("Copy this Section to Clipboard")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    directives: [
                                                      {
                                                        name: "second-event",
                                                        rawName:
                                                          "v-second-event",
                                                        value: {
                                                          method:
                                                            "registerClick",
                                                          value: "3",
                                                        },
                                                        expression:
                                                          "{ method: 'registerClick', value: '3' }",
                                                      },
                                                    ],
                                                    staticClass: "right mr-2",
                                                    on: {
                                                      click:
                                                        _vm.sectionPrintAction,
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "local_printshop\n                                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3679526061
                                    ),
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "tooltip-content" },
                                      [_vm._v("Print this Section")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    directives: [
                                                      {
                                                        name: "second-event",
                                                        rawName:
                                                          "v-second-event",
                                                        value: {
                                                          method:
                                                            "registerClick",
                                                          value: "4",
                                                        },
                                                        expression:
                                                          "{ method: 'registerClick', value: '4' }",
                                                      },
                                                    ],
                                                    staticClass: "right mr-2",
                                                    on: {
                                                      click:
                                                        _vm.sectionShareAction,
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "link\n                                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2290710070
                                    ),
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "tooltip-content" },
                                      [_vm._v("Share this Section")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.canBeBookmarked
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        directives: [
                                                          {
                                                            name: "second-event",
                                                            rawName:
                                                              "v-second-event",
                                                            value: {
                                                              method:
                                                                "registerClick",
                                                              value: "5",
                                                            },
                                                            expression:
                                                              "{ method: 'registerClick', value: '5' }",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "right mr-2",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.sectionBookmarkAction(
                                                              _vm.getId()
                                                            )
                                                          },
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                                    " +
                                                          _vm._s(
                                                            _vm.note.bookmarks
                                                              .length
                                                              ? "bookmark"
                                                              : "bookmark_border"
                                                          ) +
                                                          "\n                                "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1688128763
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "tooltip-content" },
                                          [_vm._v("Bookmark this Section")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "v-icon",
                          {
                            staticClass: "waffle-container",
                            on: { click: _vm.showActionIcons },
                          },
                          [_vm._v("apps")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "content-result-row" },
                      [
                        _c("item-header", {
                          attrs: {
                            title: _vm.getHighlightTitle(_vm.highlight),
                            meta: _vm.meta,
                            documentId: _vm.documentId,
                            breadcrumbs: _vm.breadcrumbs,
                            search: _vm.search,
                          },
                        }),
                        _vm._v(" "),
                        _c("item-content", {
                          attrs: {
                            title: _vm.getHighlightTitle(_vm.highlight),
                            content: _vm.getContent(),
                            sectionId: _vm.sectionId,
                            type: _vm.getType(),
                            breadcrumbs: _vm.breadcrumbs,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "mt-0 pt-0" }, [
                    _c(
                      "p",
                      { staticClass: "mb-0" },
                      [
                        _c("item-breadcrumb", {
                          attrs: {
                            meta: _vm.meta,
                            breadcrumbs: _vm.breadcrumbs ? _vm.breadcrumbs : {},
                            documentId: _vm.documentId,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("note-list", { attrs: { data: _vm.note } }),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { top: "", right: "", color: "success", timeout: _vm.timeout },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [_vm._v("\n        Content was copied to buffer\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }