<template>
    <span class="favoriteIcon">
        <v-row
            align="center"
            no-gutters>
            <v-col cols="auto">
                <v-tooltip
                    max-width="300"
                    transition="fade-transition"
                    v-model="tooltipOpen"
                    right
                    z-index="200">
                    <template v-slot:activator="{ on }">
                        <v-icon
                            @click="setFavorite()"
                            class="favorite primary--text"
                            v-on="on">
                            {{ is_favorite ? 'favorite' : 'favorite_border' }}
                        </v-icon>
                    </template>
                    <span class="tooltip-content"
                        @mouseenter="showTooltip"
                        @mouseleave="hideTooltip">
                        <template v-if="is_favorite">
                            Remove from Favorites
                        </template>
                        <template v-else>
                            Add to Favorites
                        </template>
                    </span>
                </v-tooltip>
            </v-col>
            <v-col v-if="showMessage">
                <p class="ml-2 mb-0">
                    {{is_favorite ? 'Remove from Favorites' : 'Add to Favorites'}}
                </p>
            </v-col>
        </v-row>
        <v-dialog
                v-model="subscription_prompt"
                width="500">
            <v-card>
                <v-card-title
                    class="primary white--text"
                    :class="{
                        'headline': !isMobilePort,
                        'caption mobile-title': isMobilePort
                    }">
                    Account Required for Favorites
                    <v-spacer></v-spacer>
                    <v-icon
                        small
                        class="accent2--text"
                        @click="dialogClose">
                        close
                    </v-icon>
                </v-card-title>
                <v-card-text>
                    <v-layout row>
                        <v-flex
                            xs12
                            text-center
                            class="my-4 primary--text caption">
                            <b>Flagging codes and standards titles as favorites allows you quick access
                                to the books you use the most. In order to begin building your list
                                of favorites, you will need an ICC Account. Once flagged, your favorite
                                titles will become available in your green sidebar.<br /><br />Please
                                click below to Create an ICC account or sign in with your existing
                                account to flag this title as a favorite.</b>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex
                                xs12
                                text-center>
                            <v-btn
                                :href="this.getRoute('signup')"
                                color="primary"
                            >
                                <span class="white--text">Create an Account</span>
                            </v-btn>
                            <v-btn
                                color="primary"
                                :href="login_url"
                            >
                                <span class="white--text">Sign In</span>
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
    import Mobile from './../inc/mixins/mobile';
    export default {
        data: function() {
            return {
                subscription_prompt: false,
                login_url: this.getLoginUrl(),
                is_favorite: false,
            }
        },
        props: {
            document_id: {
                required: true,
            },
            showMessage: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        mixins: [Mobile],
        computed: {
            getLoggedIn() {
                return this.$store.getters.getLoggedIn !== '';
            },
        },
        methods: {
            isFavorite() {
                let thisDoc = this.document_id;
                return _.find(this.favorites, function(o) {
                    return o.title.document_id === _.toNumber(thisDoc);
                }) !== undefined;
            },
            setFavorite() {
                if (this.getLoggedIn) {
                    this.$http.get(Routing.generate('update_favorite_status', {
                        documentId: this.document_id,
                        favoriteFlag: _.toNumber(!this.is_favorite)
                    }))
                        .then(response => {
                            if(response.data['success']) {
                                this.is_favorite = !this.is_favorite;
                                EventBus.fire("reset-favorites");
                            }
                        });
                } else {
                    this.showSubscribeModal();
                }
            },
            showSubscribeModal() {
                this.subscription_prompt = true;
            },
            dialogClose() {
                this.subscription_prompt = false;
            }
        },
        mounted() {
            this.is_favorite = this.isFavorite();
            EventBus.listen("update-favorites-after", () => this.is_favorite = this.isFavorite());

        }
    }
</script>
