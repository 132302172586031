<template>
    <v-snackbar v-model="snackbar" top right color="success" :timeout="timeout">
        <v-icon left>mdi-check-circle</v-icon> {{ text }}
    </v-snackbar>
</template>

<script>
export default {
    data() {
        return {
            snackbar: false,
            text: '',
            timeout: 5000
        }
    },
    created() {
        EventBus.listen('notification', (params) => {

            const {text, timeout} = (params || {});

            if (text) {
                this.text = text;
                this.timeout = timeout || 5000;
                this.snackbar = true;
            }

        });
    }
}
</script>
