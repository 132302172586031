var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.timeout, top: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v("\n        " + _vm._s(_vm.afterCopyMessage) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "#ff5252", text: "" },
              on: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("\n      Close\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h2", { staticClass: "primary--text headline" }, [
                  _c("span", { staticClass: "licenses-step-5" }, [
                    _vm._v("Concurrent"),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { md: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "licenses-step-5_1",
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.openConcurrencyModal()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Generate Concurrent Code\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { md: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-1 mr-2 primary",
                      on: {
                        click: function ($event) {
                          return _vm.refreshSubscriptions("Concurrent")
                        },
                      },
                    },
                    [
                      !_vm.isRefreshing
                        ? _c("span", [_vm._v("Refresh Licenses")])
                        : _c("v-progress-circular", {
                            attrs: {
                              size: 20,
                              indeterminate: "",
                              color: "white",
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("p", { staticClass: "caption mb-0" }, [
                  _vm._v(
                    "\n                    Concurrent Use licenses allow the individual who purchases the premium subscription to make access available to anyone who knows the concurrent code. Concurrent access is limited by the number of simultaneous users who are able to access the premium content and features at the same time. Concurrent codes, associated books and quantity are subject to change at the discretion of the individual who purchased the premium licenses.\n                "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-expansion-panels",
                    { attrs: { accordion: "" } },
                    [
                      _c(
                        "v-data-iterator",
                        {
                          staticClass: "elevation-0 no-margin parent-row",
                          attrs: {
                            items: _vm.concurrent_codes,
                            "items-per-page": _vm.pagination.itemsPerPage,
                            loading: _vm.loading,
                            "single-expand": true,
                            options: _vm.pagination,
                            "footer-props": {
                              itemsPerPageOptions:
                                _vm.pagination.rowsPerPageItems,
                            },
                            "server-items-length": _vm.pagination.totalItems,
                          },
                          on: {
                            "update:options": function ($event) {
                              _vm.pagination = $event
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c(
                                    "v-row",
                                    _vm._l(
                                      _vm.headers,
                                      function (header, index) {
                                        return _c(
                                          "v-col",
                                          {
                                            key: index,
                                            staticClass:
                                              "mr-2 caption font-weight-bold",
                                            class: { "ml-2": index === 0 },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(header.text) +
                                                "\n                                "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "default",
                              fn: function (data) {
                                return _vm._l(data.items, function (item) {
                                  return _c(
                                    "v-expansion-panel",
                                    { key: item.id },
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "actions",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.checkPanelEvent(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("expand_more")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(" "),
                                          _c(
                                            "v-container",
                                            {
                                              attrs: { "pa-0": "", "pr-4": "" },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { align: "center" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            item.access_code
                                                          ) +
                                                          "\n                                                "
                                                      ),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { right: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "clipboard",
                                                                                rawName:
                                                                                  "v-clipboard:copy",
                                                                                value:
                                                                                  item.access_code,
                                                                                expression:
                                                                                  "item.access_code",
                                                                                arg: "copy",
                                                                              },
                                                                              {
                                                                                name: "clipboard",
                                                                                rawName:
                                                                                  "v-clipboard:success",
                                                                                value:
                                                                                  _vm.onCopy,
                                                                                expression:
                                                                                  "onCopy",
                                                                                arg: "success",
                                                                              },
                                                                              {
                                                                                name: "clipboard",
                                                                                rawName:
                                                                                  "v-clipboard:error",
                                                                                value:
                                                                                  _vm.onError,
                                                                                expression:
                                                                                  "onError",
                                                                                arg: "error",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "pl-2",
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                              },
                                                                          },
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                            file_copy\n                                                        "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "tooltip-content",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.copyMessage
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-col", [
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(item.licenses) +
                                                        "\n                                            "
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("v-col", [
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          _vm._f("moment")(
                                                            item.created_at,
                                                            "MMMM Do, YYYY"
                                                          )
                                                        ) +
                                                        "\n                                            "
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "auto" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "mr-2 error",
                                                          attrs: { small: "" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeCodeAlertModal(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Remove\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: { small: "" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openConcurrencyModal(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Edit\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: { small: "" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.getCodeAccessLogs(
                                                                item.id,
                                                                item.access_code
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Usage Report\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-expansion-panel-content",
                                        { staticClass: "grey lighten-4" },
                                        [
                                          _c(
                                            "v-container",
                                            { staticClass: "pt-0 pb-0" },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c("v-data-table", {
                                                    staticClass:
                                                      "no-margin details-row",
                                                    attrs: {
                                                      headers: _vm.license,
                                                      items:
                                                        item.assigned_subscriptions,
                                                      "hide-default-footer": "",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "item",
                                                          fn: function ({
                                                            item,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "tr",
                                                                {
                                                                  staticClass:
                                                                    "green lighten-5",
                                                                },
                                                                [
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle-2",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-layout",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              row: "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-flex",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  shrink:
                                                                                    "",
                                                                                  "align-self-center":
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "a",
                                                                                {
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.bookLink(
                                                                                          item
                                                                                            .subscription
                                                                                            .document_info
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "graphic",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          title:
                                                                                            item
                                                                                              .subscription
                                                                                              .document_info,
                                                                                          thumbnail:
                                                                                            "",
                                                                                          width:
                                                                                            "50px",
                                                                                          additionalClasses:
                                                                                            "mt-2 mr-2",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-flex",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  "align-self-center":
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "a",
                                                                                {
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.bookLink(
                                                                                          item
                                                                                            .subscription
                                                                                            .document_info
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "book-title",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          title:
                                                                                            _vm.getBookTitle(
                                                                                              item
                                                                                                .subscription
                                                                                                .document_info
                                                                                            ),
                                                                                          print_version_edition:
                                                                                            _vm.printEdition(
                                                                                              item
                                                                                                .subscription
                                                                                                .document_info
                                                                                                .print_version_edition
                                                                                                .version
                                                                                            ),
                                                                                          printing_text:
                                                                                            item
                                                                                              .subscription
                                                                                              .document_info
                                                                                              .print_info,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.checkSubscriptionType(
                                                                            item
                                                                              .subscription
                                                                              .subscription_type
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "moment"
                                                                          )(
                                                                            item
                                                                              .subscription
                                                                              .purchase_date,
                                                                            "MMMM Do, YYYY"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "moment"
                                                                          )(
                                                                            item
                                                                              .subscription
                                                                              .expiration_date,
                                                                            "MMMM Do, YYYY"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.checkStatus(
                                                                            item.subscription
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle-2",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.goToTheShop(
                                                                                  _vm.shopEncodesUrl
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          item
                                                                            .subscription
                                                                            .subscription_type ===
                                                                          "M"
                                                                            ? _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Manage"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Renew"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                })
                              },
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _vm._v(" "),
                          _c("template", { slot: "no-data" }, [
                            _vm.concurrent_codes.length == 0 && !_vm.loading
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "pt-12 text-md-center",
                                    attrs: { row: "" },
                                  },
                                  [_c("p", [_vm._v("No results found!")])]
                                )
                              : _vm.concurrent_codes.length == 0
                              ? _c(
                                  "div",
                                  { staticClass: "text-center pt-6" },
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        color: "primary",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("ConcurrencyModal", {
            key: _vm.componentKey,
            attrs: { "user-id": _vm.userId },
          }),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "500px" },
              model: {
                value: _vm.removeCodeModal,
                callback: function ($$v) {
                  _vm.removeCodeModal = $$v
                },
                expression: "removeCodeModal",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n                   Remove Concurrent code!\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("v-card-text", [
                    _c("p", [
                      _vm._v(
                        "\n                       All subscriptions currently configured under the concurrent code will be moved over to\n                       Personal Use and become available for assigning to another user or configuring within\n                       a different concurrent code.\n                   "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.removeCodeModal = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "error" },
                          on: {
                            click: function ($event) {
                              return _vm.removeCode()
                            },
                          },
                        },
                        [_vm._v("Remove")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }