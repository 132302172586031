import TrackDocument from '../../dashboard/quick_access/mixins/track_document_action';
import Seo from './mixins/seo';
import {ClipboardUtils} from "../../../util/ClipboardUtils";
import {showNotification} from "../../../global";
export default {
    mixins: [TrackDocument, Seo],
    methods: {
        hideActionButtonsForNonSelectedSections() {
            const allActionButtons = document.querySelectorAll('.section-action-buttons');
            allActionButtons.forEach((element) => {

                const closestSectionWrapper = element.closest('.section-action-wrapper');

                // hide tooltip ONLY if that section is NOT "selected"
                if (closestSectionWrapper && !closestSectionWrapper.classList.contains('chapter-section-selected')) {
                    element.style.display = 'none';
                }

            });
        },
        // when hovered
        showSectionActionButtonsForContentId(id) {
            this.hideActionButtonsForNonSelectedSections();

            // show only one with ID
            const activeSection = document.getElementById('section-action-buttons-' + id);
            activeSection.style.display = 'block';
        },
        wrapDisplayTitle(id, eventType = 'hover') {
            const className = eventType === 'hover' ? 'wrap-display-title' : 'wrap-selected-display-title';

            // Remove the existing class from all elements with the class name
            document.querySelectorAll(`.${className}`).forEach(element => {
                element.classList.remove(className);
            });

            // Attempt to add the class to the main display title element
            let targetElement = document.getElementById(`text-id-${id}`);
            if (targetElement && !targetElement.classList.contains(className)) {
                targetElement.classList.add(className);
                return;
            }

            const wrapperElement = document.getElementById(`action-wrapper-${id}`);
            // should have atleast 2 children of every section, 1. toolbar icons and 2. Title/Content
            if (!wrapperElement || wrapperElement.children.length < 2) return;

            const contentSection = wrapperElement.children[1];

            if (contentSection) {
                if (contentSection.tagName === 'FIGCAPTION') { // using for tables
                    contentSection.classList.add(className);
                } else if (contentSection.tagName === 'P') { // if secton has no title heading then display toolbar above the paragraph.
                    const toolBarElement = wrapperElement.children[0]?.firstChild;
                    if (toolBarElement) {
                        toolBarElement.style.setProperty('margin-top', '-16px', 'important');
                    }
                }
            }
        },
        // when clicked
        toggleSectionSelectForContent(id) {
            this.addCanonicalTag();

            const section = document.getElementById('action-wrapper-'+id);

            if (!section) return;

            if (this.lastAccessedSection !== section) {
                this.lastAccessedSection = section;

                let title = '';

                if (section.getElementsByTagName('h1').length > 0) {
                    title = section.getElementsByTagName('h1')[0].textContent;
                } else if (section.getElementsByClassName('table_title').length > 0) {
                    title = section.getElementsByClassName('table_title')[0].textContent;
                }

                // capturing which sections are accessed the most often: https://icc-it.atlassian.net/browse/PRA-2871
                this.createAccessedDocument(this.documentid, id, title, 'book_content');
            }

            const selectedClassName = 'chapter-section-selected';

            // remove "selected" styling from all sections, before applying "selected" styling to just the one we want below
            const allWrappers = document.querySelectorAll('.section-action-wrapper');
            allWrappers.forEach(element => {
                element.classList.remove(selectedClassName);
            });

            if (!section.classList.contains(selectedClassName)) {
                section.classList.add(selectedClassName);
            }

            if (section.children.length >= 2) {
                const contentSection = section.children[1];
                if (contentSection?.tagName === 'P') {
                    const toolBarElement = section.children[0]?.firstChild;
                    if (toolBarElement) {
                        toolBarElement.style.setProperty('padding-top', '8px', 'important');
                    }
                    contentSection.style.setProperty('margin-top', '16px', 'important');
                }
            }

            this.hideActionButtonsForNonSelectedSections();
        },
        sectionActionCopyText(sectionId) {
            const section = document.getElementById('action-wrapper-' + sectionId);
            if (!section) {
                return;
            }
            const excludedSelectors = '.notes-expansion-panel-wrapper, .tags-expansion-panel-wrapper, .section-action-button-container';
            const selectedElements = Array.from(section.children)
                .filter(element => !element.matches(excludedSelectors));

            const wrapperDiv = document.createElement('div');
            selectedElements.forEach(element => {
                const clonedElement = element.cloneNode(true);
                const highlighters = clonedElement.querySelectorAll('.highlighter');
                highlighters.forEach(highlighter => {
                    highlighter.style.backgroundColor = '';
                });
                wrapperDiv.appendChild(clonedElement);
            });

            ClipboardUtils.copyUsingNativeApi(wrapperDiv);
            showNotification("Text copied to clipboard");
        },
        sectionActionOpenNoteModal(sectionId) {
            const selectedText = window.getSelection().toString();
            if (selectedText.length > 0) {
                this.toggleNoteAction(null, sectionId);
            } else {
                this.toggleBookmarkAction(sectionId);
            }

            this.hideActionButtonsForNonSelectedSections();
        },
        togglePrintAction(id, printSelected = false, printAllSections = false) {
            let printOptionSelected = printSelected;
            let isPrintAllSections = printAllSections;
            let elemCheck = document.getElementById('section-action-buttons-'+id).nextSibling;

            if (elemCheck.classList.contains('table')) {
                let json = {};
                json.html = elemCheck.innerHTML;
                json.title = elemCheck.getElementsByClassName('table_title')[0].innerText;

                this.$http.post(
                    Routing.generate("print_figure"), {data: JSON.stringify(json)}, {emulateJSON: true}
                ).then(result => {
                    if(result.data.length) {
                        window.open(result.data, '_blank');
                    }
                });
            } else {
                let tableWidth = 998; // default
                let printElement = document.getElementById('action-wrapper-'+id);

                if(null !== printElement && !_.isUndefined(printElement)) {
                    let tbl = printElement.getElementsByTagName("table");
                    tableWidth = tbl.length ? tbl[0].offsetWidth : tableWidth;
                }
                if (!printOptionSelected) {
                    let element = document.getElementById('print-menu-' + id);
                    if (null == element || _.isUndefined(element)) {
                        // Complete chapter prints are prevented (no print menu)
                        if ((id != this.activeChapter) && this.hasSubsections(id)) {
                            this.applySectionPrintMenu(id);
                        } else {
                            this.printContent(id, printAllSections, tableWidth);
                        }
                    } else {
                        this.togglePrintMenu(id);
                    }
                } else {
                    this.printContent(id, printAllSections, tableWidth);
                }
            }
            this.trackClickedAction('Print Section');
        },
        printContent(id, printAllSections, tableWidth) {
            window.open(Routing.generate('print_content', {
                'documentId': btoa(this.documentid),
                'chapterId': btoa(this.activeChapter),
                'sectionId': btoa(id),
                'tableWidth': tableWidth,
                'printAll': printAllSections ? 1 : 0,
            }), '_blank');
        },
        copyLinkButtonClicked(id) {

            const currentSectionElement = document.querySelector('section[section-number="' + id + '"]');

            if (currentSectionElement) {
                const id = currentSectionElement.id;

                const currentUrlNoHash = window.location.href.split('#')[0];
                const newUrl = currentUrlNoHash + '#' + id;

                ClipboardUtils.copyTextToClipboard(newUrl);
                showNotification('Link copied to clipboard');
            }
        },
        setupSectionActionListeners() {
            EventBus.listen('execute-section-action-copy-text', (event) => this.sectionActionCopyText(event.sectionId));
            EventBus.listen('execute-section-action-open-note-modal', (event) => this.sectionActionOpenNoteModal(event.sectionId));
            EventBus.listen('execute-section-action-toggle-print', (event) => this.togglePrintAction(event.sectionId));
            EventBus.listen('execute-section-action-copy-link', (event) => this.copyLinkButtonClicked(event.sectionId));
        },
        toggleShareAction(id) {
            let sectionID = document.getElementById('action-wrapper-' + id);

            /** @type HTMLElement */
            let sectionParent = sectionID ? sectionID.parentElement : null;

            // #action-wrapper-17427540 => #IBC2018P4_Ch03_Sec302.1
            if (sectionParent && sectionParent.tagName === 'SECTION' && sectionParent.hasAttribute('id')) {
                sectionID = sectionParent.getAttribute('id');
            } else {
                sectionID = sectionID.getAttribute('id');
            }

            EventBus.fire('show-modal-share-sections', {
                'content_id': id,
                'document_id': this.documentid,
                'chapter_id': this.activeChapter,
                'book_name': this.activeDocumentInfo.title,
                'section_title': document.getElementById('action-wrapper-'+id).getAttribute('data-section-title'),
                'section_id': sectionID
            });
            this.trackClickedAction('Share Section');
        },
        toggleBookmarkAction(sectionId) {
            let parentDiv = document.getElementById('action-wrapper-'+sectionId);
            const sectionTitle = parentDiv.getAttribute("data-section-title");

            this.trackClickedAction('Bookmark Section');

            EventBus.fire('bookmark-modal', {
                'sectionTitle': sectionTitle,
                'sectionId': sectionId,
                'chapterId': this.activeChapter,
                'document_id' : this.activeDocumentInfo.document_id,
            });
        },
        toggleNoteAction(noteId, sectionId) {
            let parentDiv = document.getElementById('action-wrapper-'+sectionId),
                chapterTitle = parentDiv.getAttribute('data-chapter-title');

            if (_.size(this.highlightedText) > 0) {
                EventBus.fire('note-modal', {
                    'id': noteId ? noteId : null,
                    'sectionId': sectionId,
                    'chapterTitle': chapterTitle,
                    'chapterId': this.activeChapter,
                    'bookId': this.activeDocumentInfo.id,
                    'documentId': this.activeDocumentInfo.document_id,
                    'bookSku': this.activeDocumentInfo.sku,
                    'isNewXml': this.activeDocumentInfo.isNewIccXml,
                    'highlightedText' : this.highlightedText
                });
                this.trackClickedAction('Note/Highlight Section');
            }
        }
    }
}
