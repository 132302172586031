export class ClipboardUtils {

    /**
     * Deprecated. May throw exception
     * Also, this invokes 'copy' event
     *
     * @param {String} text
     */
    static copyTextToClipboard(text) {
        navigator.clipboard.writeText(text);
    }

    /**
     * May throw regular exception too
     * @param {HTMLElement} element
     * @return {Promise<void>}
     */
    static copyUsingNativeApi(element) {
        const content = element.innerHTML;
        const blobInput = new Blob([content], {type: 'text/html'});
        const clipboardItemInput = new ClipboardItem({'text/html': blobInput});
        return navigator.clipboard.write([clipboardItemInput]);
    }

    /**
     *
     * @param {HTMLElement} element
     */
    static copyRichTextToClipboard(element) {
        let range = document.createRange();
        range.selectNodeContents(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
    }

    /**
     *
     * Use best available API
     *
     * @param {HTMLElement} element
     */
    static copyToClipboard(element) {

        try {

            this.copyUsingNativeApi(element).catch((err) => {

                try {
                    this.copyRichTextToClipboard(element);
                } catch (e) {
                    this.copyTextToClipboard(element.textContent);
                }

            });

        } catch (e) {

            try {
                this.copyRichTextToClipboard(element);
            } catch (e) {
                this.copyTextToClipboard(element.textContent);
            }
        }
    }
}
