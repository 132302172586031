<template>
    <div>
        <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            top>
            {{afterCopyMessage}}
        <v-btn
          color="#ff5252"
          text
          @click="snackbar = false">
          Close
        </v-btn>
      </v-snackbar>
        <v-container>
            <v-row>
                <v-col>
                    <h2 class="primary--text headline">
                        <span class="licenses-step-5">Concurrent</span>
                    </h2>
                </v-col>
                <v-col md="auto">
                    <v-btn
                        class="licenses-step-5_1"
                        color="primary"
                        @click="openConcurrencyModal()">
                        Generate Concurrent Code
                    </v-btn>
                </v-col>
                <v-col md="auto">
                    <v-btn
                        class="ml-1 mr-2 primary"
                        @click="refreshSubscriptions('Concurrent')">
                        <span v-if="!isRefreshing">Refresh Licenses</span>
                        <v-progress-circular v-else :size="20" indeterminate color="white"></v-progress-circular>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p class="caption mb-0">
                        Concurrent Use licenses allow the individual who purchases the premium subscription to make access available to anyone who knows the concurrent code. Concurrent access is limited by the number of simultaneous users who are able to access the premium content and features at the same time. Concurrent codes, associated books and quantity are subject to change at the discretion of the individual who purchased the premium licenses.
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-expansion-panels accordion>
                        <v-data-iterator
                            :items="concurrent_codes"
                            :items-per-page="pagination.itemsPerPage"
                            :loading="loading"
                            :single-expand="true"
                            :options.sync="pagination"
                            :footer-props="{itemsPerPageOptions: pagination.rowsPerPageItems}"
                            class="elevation-0 no-margin parent-row"
                            :server-items-length="pagination.totalItems">
                            <template v-slot:header>
                                <v-row>
                                    <v-col
                                        v-for="(header, index) in headers"
                                        :key="index"
                                        :class="{'ml-2': index === 0}"
                                        class="mr-2 caption font-weight-bold">
                                        {{header.text}}
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:default="data">
                                <v-expansion-panel v-for="item in data.items" :key="item.id">
                                    <v-expansion-panel-header>
                                        <template v-slot:actions>
                                            <v-icon @click="checkPanelEvent(item)" color="primary">expand_more</v-icon>
                                        </template>
                                        <v-container pa-0 pr-4>
                                            <v-row align="center">
                                                <v-col>
                                                    {{item.access_code}}
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon
                                                                v-clipboard:copy="item.access_code"
                                                                v-clipboard:success="onCopy"
                                                                v-clipboard:error="onError"
                                                                @click.stop=""
                                                                small
                                                                class="pl-2"
                                                                v-on="on">
                                                                file_copy
                                                            </v-icon>
                                                        </template>
                                                        <span class="tooltip-content">{{copyMessage}}</span>
                                                    </v-tooltip>
                                                </v-col>
                                                <v-col>
                                                    {{item.licenses}}
                                                </v-col>
                                                <v-col>
                                                    {{item.created_at | moment("MMMM Do, YYYY")}}
                                                </v-col>
                                                <v-col cols="auto">
                                                    <v-btn
                                                        small
                                                        class="mr-2 error"
                                                        @click="removeCodeAlertModal(item)">
                                                        Remove
                                                    </v-btn>
                                                    <v-btn
                                                        small
                                                        class="mr-2"
                                                        @click="openConcurrencyModal(item)">
                                                        Edit
                                                    </v-btn>
                                                    <v-btn
                                                        small
                                                        class="mr-2"
                                                        @click="getCodeAccessLogs(item.id, item.access_code)">
                                                        Usage Report
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="grey lighten-4">
                                        <v-container class="pt-0 pb-0">
                                            <v-row>
                                                <v-data-table
                                                    :headers="license"
                                                    :items="item.assigned_subscriptions"
                                                    hide-default-footer
                                                    class="no-margin details-row">
                                                    <template v-slot:item="{ item }">
                                                        <tr class="green lighten-5">
                                                            <td class="subtitle-2">
                                                                <v-layout row>
                                                                    <v-flex shrink align-self-center>
                                                                        <a @click="bookLink(item.subscription.document_info)">
                                                                            <graphic
                                                                                :title="item.subscription.document_info"
                                                                                thumbnail
                                                                                width="50px"
                                                                                additionalClasses="mt-2 mr-2"
                                                                                >
                                                                            </graphic>
                                                                        </a>
                                                                    </v-flex>
                                                                    <v-flex align-self-center>
                                                                        <a @click="bookLink(item.subscription.document_info)">
                                                                            <book-title
                                                                                :title="getBookTitle(item.subscription.document_info)"
                                                                                :print_version_edition="printEdition(item.subscription.document_info.print_version_edition.version)"
                                                                                :printing_text="item.subscription.document_info.print_info"></book-title>
                                                                        </a>
                                                                    </v-flex>
                                                                </v-layout>
                                                            </td>
                                                            <td class="subtitle-2">{{checkSubscriptionType(item.subscription.subscription_type)}}</td>
                                                            <td class="subtitle-2">{{item.subscription.purchase_date | moment("MMMM Do, YYYY") }}</td>
                                                            <td class="subtitle-2">{{item.subscription.expiration_date | moment("MMMM Do, YYYY")}}</td>
                                                            <td class="subtitle-2">{{checkStatus(item.subscription)}}</td>
                                                            <td class="subtitle-2">
                                                                <v-btn
                                                                    small
                                                                    @click="goToTheShop(shopEncodesUrl)">
                                                                    <span v-if="item.subscription.subscription_type === 'M'">Manage</span>
                                                                    <span v-else>Renew</span>
                                                                </v-btn>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </v-data-table>
                                            </v-row>
                                        </v-container>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </template>
                            <template slot="no-data">
                                <div
                                    v-if="concurrent_codes.length == 0 && !loading"
                                    row
                                    class="pt-12 text-md-center">
                                    <p>No results found!</p>
                                </div>
                                <div
                                    v-else-if="concurrent_codes.length == 0"
                                    class="text-center pt-6">
                                    <v-progress-circular
                                        indeterminate
                                        color="primary">
                                    </v-progress-circular>
                                </div>
                            </template>
                        </v-data-iterator>
                    </v-expansion-panels>
                </v-col>
            </v-row>
            <ConcurrencyModal :user-id="userId" :key="componentKey"></ConcurrencyModal>
            <v-dialog v-model="removeCodeModal" max-width="500px">
                <v-card>
                    <v-card-title>
                       Remove Concurrent code!
                    </v-card-title>
                    <v-card-text>
                       <p>
                           All subscriptions currently configured under the concurrent code will be moved over to
                           Personal Use and become available for assigning to another user or configuring within
                           a different concurrent code.
                       </p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="removeCodeModal=false">Cancel</v-btn>
                            <v-btn color="error" @click="removeCode()">Remove</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>
<script>
    import BookTitle from './parts/content_title';
    import Licenses from '../../inc/mixins/licenses.js';
    import Concurrent from './mixins/concurrent.js';
    import Graphic from "../../titles/cover/Graphic";
    import Constants from '../../../constants/index';

    export default {
        mixins: [Licenses, Concurrent],
        components: {
            BookTitle,
            Graphic
        },
        data() {
            return {
                headers: [
                    {text: 'Code', align: 'left', value: 'access_code', expand: true},
                    {text: 'Configured Licenses', align: 'left', value: 'licenses'},
                    {text: 'Created', value: 'created_at'},
                    {text: 'Actions', value: null, sortable: false}
                ],
                license: [
                    {text: 'Title', align: 'left', value: 'subscription.document_info.year.year'},
                    {text: 'Subscription Type', value: 'subscription.subscription_type'},
                    {text: 'Purchase Date', value: 'subscription.purchase_date'},
                    {text: 'Expiration Date', value: 'subscription.expiration_date'},
                    {text: 'Subscription Status', value: 'subscription_status'},
                    {text: 'Actions', value: null, align: 'center', sortable: false}
                ],
                panel: [],
                snackbar: false,
                timeout: 3500,
                copyMessage: 'Copy to clipboard',
                afterCopyMessage: '',
                shopEncodesUrl: Constants.shopEncodesUrl
            };
        },
        methods: {
            checkPanelEvent(panelKey){
                if(!this.panel.length){
                    this.panel = [...Array(this.concurrent_codes.length).keys()].map(_ => false);
                }
                let array = [];
                this.panel.forEach(function(item, key){
                    array[key] = (key === panelKey.index) ? !item : item;
                });

                this.panel = array;
            },
            onCopy: function (e) {
                this.afterCopyMessage = 'Concurrent code has been copied to your clipboard';
                this.snackbar = true;
            },
            onError: function (e) {
                this.afterCopyMessage = 'Concurrent code was not copied to your clipboard due to some error';
            }
        }
    }
</script>
